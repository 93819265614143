/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import React, { useEffect, useState } from 'react'

import { OverlayPanel } from 'primereact/overlaypanel'

const tieredListStyle = css`
  padding: 0;
  margin: 0;
  width: 200px;
  margin-left: -150px;
  list-style: none;
  background: var(--bg-gradient);
  box-shadow: 0px 0px 25px 0px rgba(60, 114, 255, 0.28);
  border-radius: 10px;
`

const tieredListInnerStyle = css`
  margin: 0;
  padding: 0;
  left: calc(100%);
  width: 80%;
  bottom: 0;
  opacity: 0;
  position: absolute;
  visibility: hidden;
`

const tieredListInnerStyleInFullscreen = css`
  bottom: 0;
  top: unset;
  width: 70%;
`

const tieredItemStyle = css`
  padding: 12px 20px 15px 20px;
  position: relative;
  cursor: pointer;
  font-weight: 500;
  color: var(--text-color-secondary);

  &:hover {
    color: var(--text-color);

    ul {
      opacity: 1;
      visibility: visible;
    }
  }


  &:not(:first-child) {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 12px;
  }
`

const tieredOverPanelStyle = css`
  &.p-overlaypanel.live-player-select-lock-time-panel.p-component {
    background: transparent;
    width: auto;
    max-height: unset;
    overflow: unset;
  }

  .p-overlaypanel-content {
    padding: 0;
  }
`

export type TieredItemValue =
  | {
      type: 'lock'
      periodInSeconds: number
    }
  | { type: 'unlock' }

export type TTieredItem =
  | {
      id: string
      label: string
      items: TTieredItem[]
    }
  | {
      value: TieredItemValue
      id: string
      label: string
    }

const generateList = (onSelect: (val: TieredItemValue) => void, items?: TTieredItem[]) => {
  return items?.map((item, index) => {
    const { id, label } = item
    const nestedItems = 'items' in item ? item.items : []
    const itemValue = 'value' in item ? item.value : null

    const isFullscreen = Boolean(document.fullscreenElement)
    const styles = [tieredListStyle, tieredListInnerStyle]
    if (isFullscreen) styles.push(tieredListInnerStyleInFullscreen)

    return (
      <li
        key={`${id}${index}`}
        css={tieredItemStyle}
        onClick={itemValue ? () => onSelect(itemValue) : undefined}>
        <span>{label}</span>
        {Boolean(nestedItems.length) && <ul css={styles}>{generateList(onSelect, nestedItems)}</ul>}
      </li>
    )
  })
}

// TODO WHen primereact updated get TieredList component from primereact
export const TieredList = (props: {
  model: TTieredItem[]
  onSelect: (val: TieredItemValue) => void
  reference: React.MutableRefObject<OverlayPanel>
}) => {
  const { model, onSelect, reference } = props
  const [appendElement, setAppendElement] = useState<Element>(document.body)

  useEffect(() => {
    document.addEventListener('fullscreenchange', () =>
      setAppendElement(document.fullscreenElement || document.body),
    )
  }, [])

  return (
    <OverlayPanel
      className="live-player-select-lock-time-panel"
      css={tieredOverPanelStyle}
      dismissable
      ref={reference}
      appendTo={appendElement}>
      <ul css={tieredListStyle}>{generateList(onSelect, model)}</ul>
    </OverlayPanel>
  )
}
